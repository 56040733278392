import { IJwtInfo } from "../stores/auth/auth.interface";
import useAuthStore from "../stores/auth/auth.store";

function usePermissions() {
  function hasAccessByPermissions(permissionsArray: Array<number>): boolean {
    if (!permissionsArray.length) return false;
    const authStore = useAuthStore();
    const { jwtInfo }: { jwtInfo: IJwtInfo } = authStore.getAuthState;
    const { permissions } = jwtInfo;
    const hasPermission = permissions?.some((p) =>
      permissionsArray.includes(p)
    );
    return hasPermission || false;
  }

  return {
    hasAccessByPermissions
  };
}

export default usePermissions;

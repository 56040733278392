
  import { defineComponent, useContext } from "@nuxtjs/composition-api";
  import { isSpecialPartner } from "@loadsure/core";
  import { useCurrentUser } from "../use/useFireAuth";
  import useAccessHelper from "../use/useAccessHelper";

  export default defineComponent({
    name: "UserMenu",

    setup() {
      const context = useContext();
      const {
        authUser,
        email,
        logout,
        allPartners,
        recentPartners,
        partnerId
      } = useCurrentUser(context);

      const { isLoadsureAdmin } = useAccessHelper();

      return {
        isSpecialPartner,
        authUser,
        email,
        recentPartners,
        allPartners,
        logout,
        isLoadsureAdmin,
        partnerId
      };
    }
  });

import {
  Ref,
  inject,
  onMounted,
  provide,
  reactive,
  ref
} from "@nuxtjs/composition-api";

export const useGlobalProvider = () => {
  // Alert behavior
  const isGlobalAlertShow = ref(false);
  const globalAlertMessage = ref("");
  const globalAlertType = ref("success");
  const globalAlertTimeout = ref(10);
  function isMobileSize() {
    return window.innerWidth < 576;
  }
  const isMobile = ref(false);

  onMounted(() => {
    isMobile.value = isMobileSize();
    window.addEventListener("resize", () => {
      isMobile.value = isMobileSize();
    });
  });

  const showGlobalAlert = () => {
    isGlobalAlertShow.value = true;
  };

  const hideGlobalAlert = () => {
    isGlobalAlertShow.value = false;
  };

  provide("isGlobalAlertShow", isGlobalAlertShow);
  provide("globalAlertMessage", globalAlertMessage);
  provide("globalAlertType", globalAlertType);
  provide("showGlobalAlert", showGlobalAlert);
  provide("hideGlobalAlert", hideGlobalAlert);
  provide("globalAlertTimeout", globalAlertTimeout);
  provide("isMobile", isMobile);

  // Banner behavior
  const globalBanner = reactive({
    show: false,
    text: "",
    type: "warning",
    buttonText: "",
    handleBannerClick: () => ({})
  });

  const showGlobalBanner = () => {
    globalBanner.show = true;
  };

  const hideGlobalBanner = () => {
    globalBanner.show = false;
  };

  provide("globalBanner", globalBanner);
  provide("showGlobalBanner", showGlobalBanner);
  provide("hideGlobalBanner", hideGlobalBanner);

  const globalDialogShareAccess = reactive({
    openModal: false,
    title: "",
    info: "",
    email: "",
    message: "",
    handleDialogSendClick: () => {
      /* Empty function */
    },
    shareAccessErrors: [],
    overlaySend: false,
    overlayEmail: false,
    errorEmail: "",
    showInfoBox: false,
    textMainInfoBox: "",
    textSecondaryInfoBox: [],
    iconInfoBox: "",
    colorInfoBox: "",
    borderWarning: false,
    authorizedUsers: [],
    chipsGroupText: "",
    handleGlobalDialogShareAccessEmailBlur: () => {
      /* Empty function */
    },
    hideGlobalDialogShareAccess: () => {
      /* Empty function */
    },
    error: false
  });

  const showGlobalDialogShareAccess = () => {
    globalDialogShareAccess.openModal = true;
  };

  provide("globalDialogShareAccess", globalDialogShareAccess);
  provide("showGlobalDialogShareAccess", showGlobalDialogShareAccess);

  return {
    isGlobalAlertShow,
    globalAlertMessage,
    globalAlertType,
    showGlobalAlert,
    hideGlobalAlert,
    globalAlertTimeout,
    globalBanner,
    showGlobalBanner,
    hideGlobalBanner,
    isMobile,
    globalDialogShareAccess,
    showGlobalDialogShareAccess
  };
};

export const useGlobalInject = () => {
  // Alert Behavior
  const isGlobalAlertShow = inject("isGlobalAlertShow") as Ref<boolean>;
  const globalAlertMessage = inject("globalAlertMessage") as Ref<string>;
  const globalAlertType = inject("globalAlertType") as Ref<string>;
  const showGlobalAlert = inject("showGlobalAlert") as () => void;
  const hideGlobalAlert = inject("hideGlobalAlert") as () => void;
  const globalAlertTimeout = inject("globalAlertTimeout") as Ref<boolean>;
  const isMobile = inject("isMobile");

  // Banner Behavior
  const globalBanner = inject("globalBanner") as {
    show: boolean;
    text: string;
    type: string;
    buttonText: string;
    handleBannerClick: () => void;
  };
  const showGlobalBanner = inject("showGlobalBanner") as () => void;
  const hideGlobalBanner = inject("hideGlobalBanner") as () => void;

  const globalDialogShareAccess = inject("globalDialogShareAccess") as {
    openModal: boolean;
    title: string;
    info: string;
    email: string;
    message: string;
    handleDialogSendClick: (claim: any, vuelidate: any) => Promise<void>;
    shareAccessErrors: string[];
    overlaySend: boolean;
    overlayEmail: boolean;
    errorEmail: string;
    showInfoBox: boolean;
    textMainInfoBox: string;
    textSecondaryInfoBox: string[];
    iconInfoBox: string;
    colorInfoBox: string;
    borderWarning: boolean;
    authorizedUsers: string[];
    chipsGroupText: string;
    handleGlobalDialogShareAccessEmailBlur: (vuelidate: any) => Promise<void>;
    hideGlobalDialogShareAccess: (vuelidate: any) => void;
    error: boolean;
  };

  const showGlobalDialogShareAccess = inject(
    "showGlobalDialogShareAccess"
  ) as () => void;

  return {
    isGlobalAlertShow,
    globalAlertMessage,
    globalAlertType,
    showGlobalAlert,
    hideGlobalAlert,
    globalAlertTimeout,
    globalBanner,
    showGlobalBanner,
    hideGlobalBanner,
    isMobile,
    globalDialogShareAccess,
    showGlobalDialogShareAccess
  };
};

import { render, staticRenderFns } from "./InputGeneric.vue?vue&type=template&id=2e0a0a80&scoped=true&lang=pug"
import script from "./InputGeneric.vue?vue&type=script&lang=ts"
export * from "./InputGeneric.vue?vue&type=script&lang=ts"
import style0 from "./InputGeneric.vue?vue&type=style&index=0&id=2e0a0a80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e0a0a80",
  null
  
)

export default component.exports
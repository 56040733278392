
  import { defineComponent } from "@nuxtjs/composition-api";
  import { useOverlayDisplayInject } from "../use/useOverlay";

  export default defineComponent({
    name: "CustomizableOverlay",

    setup() {
      return useOverlayDisplayInject();
    }
  });

import { ePermissionIds } from "@loadsure/core";
import { iRoute } from "../types/types";

const UnderwriterRoutes: iRoute[] = [
  {
    paths: ["/underwriter/list"],
    permissions: [
      ePermissionIds.VIEW_THAMES_REFERRALS,
      ePermissionIds.MANAGE_THAMES_REFERRALS,
      ePermissionIds.VIEW_ORINOCO_REFERRALS,
      ePermissionIds.MANAGE_ORINOCO_REFERRALS
    ],
    allowAccessAll: false
  },
  {
    paths: ["/underwriter/annualCargo/list"],
    permissions: [
      ePermissionIds.VIEW_OCEAN_CARGO_REFERRALS,
      ePermissionIds.MANAGE_OCEAN_CARGO_REFERRALS
    ],
    allowAccessAll: false
  }
];

export default UnderwriterRoutes;

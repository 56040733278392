import { ePermissionIds } from "@loadsure/core";
import { iRoute } from "../types/types";

const ContactsRoutes: iRoute[] = [
  {
    paths: ["/contacts", "/contacts/edit/NEW", "/contacts/edit/:id"],
    permissions: [
      ePermissionIds.EDIT_MY_CONTACTS,
      ePermissionIds.EDIT_COMPANY_CONTACTS,
      ePermissionIds.EDIT_ALL_CONTACTS,
      ePermissionIds.VIEW_MY_CONTACTS,
      ePermissionIds.VIEW_COMPANY_CONTACTS,
      ePermissionIds.VIEW_ALL_CONTACTS
    ],
    allowAccessAll: false
  }
];

export default ContactsRoutes;

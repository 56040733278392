import { inject, provide, reactive } from "@nuxtjs/composition-api";

type Error = {
  message: string;
  errors?: string[];
};

type AlertType = "success" | "warning" | "error";

type Alert = {
  message: string;
  type: AlertType;
  timeout: number;
  dismissable: boolean;
};

type Overlay = {
  show: boolean;
  loading: boolean;
  error: Error | undefined;
  alert: Alert | undefined;
};

export const useOverlayProvide = () => {
  const overlay: Overlay = reactive({
    show: false,
    loading: false,
    error: undefined,
    alert: undefined
  });
  provide("overlay", overlay);

  const hideOverlay = () => {
    overlay.show = false;
    overlay.loading = false;
    overlay.error = undefined;
    overlay.alert = undefined;
  };
  provide("hideOverlay", hideOverlay);

  return {
    overlay,
    hideOverlay
  };
};

export const useOverlayDisplayInject = () => {
  const overlay = inject("overlay") as Overlay;
  const hideOverlay = inject("hideOverlay") as () => void;

  return {
    overlay,
    hideOverlay
  };
};

export const useOverlayInject = () => {
  const overlay = inject("overlay") as Overlay;

  const showLoadingOverlay = () => {
    overlay.show = true;
    overlay.loading = true;
  };

  const showErrorOverlay = (message: string, errors?: string[]) => {
    overlay.show = true;
    overlay.loading = false;
    overlay.error = {
      message,
      errors
    };
  };

  const showAlertOverlay = (
    message: string,
    type: AlertType = "success",
    timeout = 2,
    dismissable = true
  ) => {
    overlay.show = true;
    overlay.loading = false;
    overlay.alert = {
      message,
      type,
      timeout,
      dismissable
    };
  };

  const hideOverlay = inject("hideOverlay") as () => void;

  return {
    showLoadingOverlay,
    showErrorOverlay,
    showAlertOverlay,
    hideOverlay
  };
};

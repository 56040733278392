
  import { defineComponent } from "@nuxtjs/composition-api";

  export default defineComponent({
    name: "HrText",
    props: {
      text: {
        type: String,
        required: true
      }
    }
  });

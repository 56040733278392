
import { defineComponent, ref, useContext } from "@nuxtjs/composition-api";
import { useCurrentUser } from "../use/useFireAuth";
import { useInternetTradingPolicyInject } from "../use/useInternetTradingPolicy";
import useAuthStore from "../stores/auth/auth.store";

export default defineComponent({
  name: "InternetTradingPolicyPopUp",

  setup() {
    const context = useContext();
    const { route, redirect } = context;
    const { showITP, acceptITP, loading, viewITPDetails, showITPDetails, closeITPErrorDetails, showITPError, emailOverride } =
      useInternetTradingPolicyInject(context);
    const authStore = useAuthStore();
    const { logout } = useCurrentUser(context);
    // refs
    const checkbox = ref(false);
    // methods
    const onLogout = () => {
      logout();
      authStore.resetAuthState();
      if (route.value.path !== "/") {
        redirect("/");
      }
    };

    const closePopup = () => {
      if (!emailOverride.value) {
        onLogout();
      } else {
        showITPError.value = true;
      }
    };

    return {
      showITP,
      showITPDetails,
      checkbox,
      viewITPDetails,
      acceptITP,
      loading,
      closePopup,
      showITPError,
      closeITPErrorDetails
    };
  }
});

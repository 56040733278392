
  import { defineComponent } from "@nuxtjs/composition-api";
  import CONSTANTS from "../../../use/useConstants";

  export default defineComponent({
    name: "InfoBox",
    props: {
      iconName: {
        type: String,
        default: "info"
      },
      iconColor: {
        type: String,
        default: CONSTANTS.colors.secondary500
      },
      iconSize: {
        type: Number,
        default: 24
      },
      iconOffset: {
        type: String,
        default: ""
      },
      zIndex: {
        type: [Number, String],
        default: "inherit"
      },
      borderWarning: {
        type: Boolean,
        default: false
      },
      borderError: {
        type: Boolean,
        default: false
      },
      topMargin: {
        type: String,
        default: ""
      },
      bottomMargin: {
        type: String,
        default: ""
      }
    }
  });


  import { defineComponent, useContext } from "@nuxtjs/composition-api";
  import { useCurrentUserProvide } from "../use/useFireAuth";
  import { useInternetTradingPolicyProvide } from "../use/useInternetTradingPolicy";
  import { useOverlayProvide } from "../use/useOverlay";
  import { useSiteConfigurationProvide } from "../use/useSiteConfiguration";

  export default defineComponent({
    setup() {
      const context = useContext();
      const { internetTradingPolicy } = useSiteConfigurationProvide(context);
      const { userPolicies } = useCurrentUserProvide(context);
      useInternetTradingPolicyProvide(internetTradingPolicy, userPolicies);
      useOverlayProvide();
    }
  });

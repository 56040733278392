
  import { PropType, computed, defineComponent } from "@nuxtjs/composition-api";
  import CONSTANTS from "../../../use/useConstants";

  export default defineComponent({
    name: "Chip",
    props: {
      label: {
        type: String,
        required: false,
        default: undefined
      },
      icon: {
        type: String,
        required: false,
        default: ""
      },
      fileInfo: {
        type: Object,
        default: undefined
      },
      downloadFunction: {
        type: Function as PropType<(param) => void>,
        default: () => undefined
      },
      closeIcon: {
        type: Boolean,
        default: false
      },
      closeAction: {
        type: Function,
        default: () => undefined
      },
      singleFile: Boolean,
      forceEnable: Boolean,
      theme: {
        type: String,
        default: "default"
      }
    },
    emits: ["click"],
    setup(props, { emit }) {
      const convertedFileSize = computed(() => {
        const unitMeasureFactor = 1000;
        const fileSizeToKB = props.fileInfo.size / unitMeasureFactor;
        return Math.floor(fileSizeToKB);
      });

      const fileLabel = computed(() => {
        if (props.fileInfo) {
          const { documentName } = props.fileInfo;
          return `${documentName} (${convertedFileSize.value}KB)`;
        }
        return "";
      });

      const isEnabled = computed(() => props.fileInfo || props.forceEnable);

      const isSingleFile = computed(() => props.singleFile);

      const handleClick: any = (fileInfo) => {
        if (!fileInfo && props.forceEnable) {
          emit("click");
        } else {
          props.downloadFunction(fileInfo);
        }
      };

      const themeExtended = computed(() => {
        if (props.theme.includes(" ")) {
          return props.theme.split(" ").join("-").toLowerCase();
        }
        return props.theme;
      });

      return {
        isEnabled,
        isSingleFile,
        fileLabel,
        handleClick,
        iconColor: CONSTANTS.colors.primary100,
        themeExtended
      };
    }
  });


  import { defineComponent } from "@nuxtjs/composition-api";

  export default defineComponent({
    name: "ButtonSpinner",
    props: {
      secondary: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  });

interface UseLoggerReturn {
  logger: (msg: string, obj?: unknown) => void;
}

/* eslint-disable no-console */
export const useLogger = (): UseLoggerReturn => {
  const getRandomColor = (): string => {
    const randomValue = () => Math.floor(Math.random() * 128) + 127;
    const toHex = (n: number): string => n.toString(16).padStart(2, "0").toUpperCase();
    return `#${toHex(randomValue())}${toHex(randomValue())}${toHex(randomValue())}`;
  };
  const logger = (msg: string, obj?: unknown): void => {
    if (!msg) return;
    const styles = `color: red; font-weight: bold; background: ${getRandomColor()}; color: white; border-radius: 5px; padding: 2px 5px;`;
    // eslint-disable-next-line no-unused-expressions
    obj !== undefined ? console.log(`%c${msg}`, styles, obj) : console.log(`%c${msg}`, styles);
  };
  return { logger };
};

interface iGTMTimerEvent {
  startTime?: string;
  userId: string | null | undefined;
  partnerId: string | undefined;
  timerInterval?: number;
  timerEventNumber?: number;
  triggers?: string;
  product: string;
  tagVersion?: string;
  isInternational?: boolean;
}

export interface iGTMEvent {
  event: string;
  pageUrl: string;
  pageTitle: string;
  gtm: iGTMTimerEvent;
}

export interface iUseGTM {
  // eslint-disable-next-line no-unused-vars
  initializeGTM: (event: iGTMEvent) => void;
  // eslint-disable-next-line no-unused-vars
  pushNewEvent: (event: iGTMEvent) => void;
  // eslint-disable-next-line no-unused-vars
  fireEvent: (event: iGTMEvent) => void;
  finishGTM: () => void;
}

/**
 *  Create GTM instance.
 * @param self A global "this" instance of vue
 * @param interval Interval time to be executed a event in seconds
 * @param limit Limit time while is going to be executed this event in seconds. Defautl value 1800 sg.
 * @returns iUseGTM
 */
export default function useGTM(
  self: any,
  interval: number = 5,
  limit: number = 1800
): iUseGTM {
  let timerEventNumber = 0;
  let timerId;
  const timerInterval = interval * 1000;
  const timeLimit = limit * 1000;
  const startTime = new Date().toISOString();

  /**
   * Function to push a GTM custom event
   * @param event
   */
  const fireEvent = (event: iGTMEvent): void => {
    const params = event;
    timerEventNumber += 1;
    // adding dinamic params
    params.gtm.timerEventNumber = timerEventNumber;
    params.gtm.timerInterval = timerInterval;
    params.gtm.startTime = startTime;
    params.gtm.triggers = "";
    // sending event to gtm
    self.$gtm.push(params);
    // stopping event if maximun limit time is reached
    if (timerEventNumber * timerInterval >= timeLimit) clearInterval(timerId);
  };

  return {
    /**
     * Function to start running a GTM timer event.
     * @param event iGTMEvent
     */
    initializeGTM: (event: iGTMEvent): void => {
      // firing timer events
      timerId = setInterval(() => fireEvent(event), timerInterval);
    },
    /**
     *  Function to stop a previus GTM timer event and start a new one
     * @param event iGTMEvent
     */
    pushNewEvent: (event: iGTMEvent): void => {
      timerEventNumber = 0;
      clearInterval(timerId);
      // firing timer events
      timerId = setInterval(() => fireEvent(event), timerInterval);
    },
    /**
     * Function to stop a GTM timer event is running.
     */
    finishGTM: () => {
      clearInterval(timerId);
    },
    fireEvent
  };
}


  import { defineComponent } from "@nuxtjs/composition-api";

  export default defineComponent({
    name: "CardError",
    props: {
      width: {
        type: Number,
        default: 300
      },
      message: {
        type: String,
        default: ""
      },
      showErrorMessage: {
        type: Boolean,
        default: false
      },
      idPrefix: {
        type: String,
        default: "error-card"
      },
      suppressErrorSecondaryMessage: {
        type: Boolean,
        default: false
      },
      changeErrorSecondaryMessage: {
        type: Boolean,
        default: false
      }
    }
  });

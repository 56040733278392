import { iRoute } from "../types/types";

const DashboardRoutes: iRoute[] = [
  {
    paths: ["/dashboard"],
    permissions: [],
    allowAccessAll: true
  }
];

export default DashboardRoutes;

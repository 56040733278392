const APIPath = "https://akiya-api.vercel.app"

export async function GetData() {
    const data = await fetch(`${APIPath}`)
    const json = await data.json()
    return json
}

export async function GetQuery(query: string) {
    const data = await fetch(`${APIPath}?s=${query}`)
    const json = await data.json()
    return json
}


  import { computed, defineComponent } from "@nuxtjs/composition-api";
  import CONSTANTS from "../../../use/useConstants";

  export default defineComponent({
    props: {
      iconName: {
        type: String,
        default: "face",
        required: true
      },
      iconSize: {
        type: Number,
        required: false,
        default: 24
      },
      iconColor: {
        type: String,
        required: false,
        default: CONSTANTS.colors.secondary500
      },
      marginLess: Boolean,
      marginCustom: {
        type: String,
        required: false,
        default: ""
      },
      iconType: {
        type: String,
        default: "basic",
        required: false
      }
    },
    setup(props) {
      const getIconType = () => ({
        basic: {
          name: "basic",
          class: "material-icons"
        },
        outline: {
          name: "outline",
          class: "material-icons-outlined"
        }
      });
      const currentIconType = computed(() => getIconType()[props.iconType]);
      return {
        currentIconType
      };
    }
  });

import { ePermissionIds } from "@loadsure/core";
import { iRoute } from "../types/types";

const AnnualProductRoutes: iRoute[] = [
  {
    paths: ["/annualCargo/request"],
    permissions: [],
    allowAccessAll: true
  },
  {
    paths: ["/annualCargo/details", "/annualCargo/list"],
    permissions: [
      ePermissionIds.VIEW_THAMES_REFERRALS,
      ePermissionIds.MANAGE_THAMES_REFERRALS,
      ePermissionIds.VIEW_OCEAN_CARGO_REFERRALS,
      ePermissionIds.MANAGE_OCEAN_CARGO_REFERRALS,
      ePermissionIds.VIEW_HVS_REFERRALS,
      ePermissionIds.MANAGE_HVS_REFERRALS,
      ePermissionIds.VIEW_MTC_REFERRALS,
      ePermissionIds.MANAGE_MTC_REFERRALS,
      ePermissionIds.VIEW_ORINOCO_REFERRALS,
      ePermissionIds.MANAGE_ORINOCO_REFERRALS
    ],
    allowAccessAll: true
  }
];

export default AnnualProductRoutes;

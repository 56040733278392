import Error from "./error.vue";

export default {
  title: "Layouts/Error",
  component: Error,
  // Our exports that end in "Data" are not stories.
  excludeStories: /.*Data$/
};

const Template: any = (args, { argTypes }) => ({
  components: { Error },
  props: Object.keys(argTypes),
  template: "<Error v-bind=\"$props\"/>"
});

export const Default = Template.bind({});
Default.args = {
  error: {
    statusCode: undefined
  }
};

export const Error401 = Template.bind({});
Error401.args = {
  error: {
    statusCode: 401
  }
};

export const Error404 = Template.bind({});
Error404.args = {
  error: {
    statusCode: 404
  }
};

export const Error500 = Template.bind({});
Error500.args = {
  error: {
    statusCode: 500,
    message: "Some Server Error!!!"
  }
};

/* eslint-disable import/no-extraneous-dependencies */
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip
  } from "chart.js";
import { Line } from "vue-chartjs/legacy";
import Vue from "vue";
  
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    PointElement,
    BarElement,
    CategoryScale,
    LinearScale,
    LineElement,
    Filler
  );

Vue.component("LineChart", {
	extends: Line,
	props: {
    chartData: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      gradient: null
    };
  },
	mounted () {
    this.createGradient();
    this.renderChart(this.chartData, this.options);
	},

  methods: {
    createGradient() {
      this.gradient = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 400);  
      this.gradient.addColorStop(0, "rgba(65, 159, 148, 0.6)");   
      this.gradient.addColorStop(0.1, "rgba(65, 159, 148, 0.4)");
      this.gradient.addColorStop(1, "rgba(65, 159, 148, 0)");
    }
  }
});

import { ePermissionIds } from "@loadsure/core";
import { iRoute } from "../types/types";

const CarriersRoutes: iRoute[] = [
  {
    paths: ["/carriers"],
    permissions: [
      ePermissionIds.EDIT_MY_CARRIERS,
      ePermissionIds.EDIT_COMPANY_CARRIERS,
      ePermissionIds.EDIT_ALL_CARRIERS,
      ePermissionIds.VIEW_MY_CARRIERS,
      ePermissionIds.VIEW_COMPANY_CARRIERS,
      ePermissionIds.VIEW_ALL_CARRIERS
    ],
    allowAccessAll: false
  }
];

export default CarriersRoutes;

import { render, staticRenderFns } from "./DialogGeneric.vue?vue&type=template&id=8eedd802&scoped=true&lang=pug"
import script from "./DialogGeneric.vue?vue&type=script&lang=ts"
export * from "./DialogGeneric.vue?vue&type=script&lang=ts"
import style0 from "./DialogGeneric.vue?vue&type=style&index=0&id=8eedd802&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8eedd802",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LsIcon: require('/workspace/portal/hosting/components/_base/Icon/Icon.vue').default,LsChip: require('/workspace/portal/hosting/components/_base/Chip/Chip.vue').default,LsPopover: require('/workspace/portal/hosting/components/_base/Popover/Popover.vue').default,LsButton: require('/workspace/portal/hosting/components/_base/Button/Button.vue').default,LsButtonWrapper: require('/workspace/portal/hosting/components/_base/Button/ButtonWrapper/ButtonWrapper.vue').default})


  import { defineComponent, useContext } from "@nuxtjs/composition-api";
  import useNavigation from "../../use/useNavigation";

  export default defineComponent({
    name: "NavigationDrawerV2",

    setup() {
      const context = useContext();
      const { menuItems } = useNavigation(context);

      return {
        menuItems
      };
    }
  });


  import { defineComponent } from "@nuxtjs/composition-api";

  export default defineComponent({
    name: "Banner",
    props: {
      type: {
        type: String,
        required: false,
        default: "warning"
      },
      text: {
        type: String,
        required: true
      },
      buttonText: {
        type: String,
        required: false,
        default: ""
      }
    }
  });

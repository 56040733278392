
  import {
    PropType,
    computed,
    defineComponent,
    ref
  } from "@nuxtjs/composition-api";

  export default defineComponent({
    name: "DialogGeneric",
    props: {
      openModal: {
        type: Boolean,
        required: true
      },
      title: {
        type: String,
        default: ""
      },
      info: {
        type: String,
        default: ""
      },
      disableSendButton: {
        type: Boolean,
        default: false
      },
      backArrow: {
        type: Boolean,
        default: false
      },
      hasBackground: {
        type: Boolean,
        required: false,
        default: true
      },
      chipList: {
        type: Array as PropType<string[]>,
        required: false,
        default: () => []
      },
      chipsGroupText: {
        type: String,
        required: false,
        default: ""
      },
      large: Boolean,
      centerInfoText: Boolean
    },
    emits: ["close", "submit"],
    setup(props, { emit }) {
      const showPopover = ref(false);
      const closeModal = () => {
        showPopover.value = false;
        emit("close");
      };
      const submit = () => emit("submit");
      const getFormattedChipList = computed(() => {
        const chipListClone = [...props.chipList];
        if (chipListClone.length > 3) {
          const popover = chipListClone.splice(4);
          return {
            rendered: chipListClone,
            popover
          };
        }
        return {
          rendered: chipListClone,
          popover: []
        };
      });

      const togglePopover = () => {
        showPopover.value = true;
      };

      return {
        closeModal,
        submit,
        getFormattedChipList,
        showPopover,
        togglePopover
      };
    }
  });

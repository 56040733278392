import { render, staticRenderFns } from "./Chip.vue?vue&type=template&id=5ffdf006&scoped=true&lang=pug"
import script from "./Chip.vue?vue&type=script&lang=ts"
export * from "./Chip.vue?vue&type=script&lang=ts"
import style0 from "./Chip.vue?vue&type=style&index=0&id=5ffdf006&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ffdf006",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LsIcon: require('/workspace/portal/hosting/components/_base/Icon/Icon.vue').default})


  import {
    PropType,
    defineComponent,
    onMounted,
    ref
  } from "@nuxtjs/composition-api";

  export default defineComponent({
    props: {
      list: {
        type: Array as PropType<string[]>,
        required: true
      },
      itemsClickable: Boolean
    },
    emits: ["close"],
    setup(props, { emit }) {
      const handleFocusOut = () => {
        emit("close");
      };

      const popover: any = ref(null);

      onMounted(() => {
        popover.value.focus();
      });

      return {
        handleFocusOut,
        popover
      };
    }
  });

import { render, staticRenderFns } from "./CustomizableOverlay.vue?vue&type=template&id=e2f3e868&lang=pug"
import script from "./CustomizableOverlay.vue?vue&type=script&lang=ts"
export * from "./CustomizableOverlay.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LsAlert: require('/workspace/portal/hosting/components/_base/Alert/Alert.vue').default,LsDialogInfo: require('/workspace/portal/hosting/components/_base/Dialog/DialogInfo.vue').default})

import { render, staticRenderFns } from "./CardError.vue?vue&type=template&id=e1137b58&scoped=true&lang=pug"
import script from "./CardError.vue?vue&type=script&lang=ts"
export * from "./CardError.vue?vue&type=script&lang=ts"
import style0 from "./CardError.vue?vue&type=style&index=0&id=e1137b58&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1137b58",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LsButton: require('/workspace/portal/hosting/components/_base/Button/Button.vue').default,LsButtonWrapper: require('/workspace/portal/hosting/components/_base/Button/ButtonWrapper/ButtonWrapper.vue').default})

import { ePermissionIds } from "@loadsure/core";
import { iRoute } from "../types/types";

const IntegrationRoutes: iRoute[] = [
  {
    paths: [
      "/integration/apiDocs",
      "/integration/styleGuide",
      "/integration/urlLink/playground",
      "/integration/appendix"
    ],
    permissions: [
      ePermissionIds.VIEW_INTEGRATION_SETTINGS,
      ePermissionIds.MANAGE_INTEGRATION_SETTINGS
    ],
    allowAccessAll: true
  }
];

export default IntegrationRoutes;

import {
  EditMasterPoliciesRoutes,
  EditPortalSettingsRoutes,
  LicensingRoutes,
  PartnerInvoiceRoutes,
  PartnerSettingsRoutes,
  PartnersRoutes,
  SanctionsChecksRoutes,
  TaxesRoutes,
  UsersRoutes
} from "./settings.route";
import {
  QuoteOrBuyRoutes,
  ViewCertificateRoutes,
  ViewQuoteRoutes
} from "./quoteOrBuy.route";
import { iRoute } from "../types/types";
import AboutRoutes from "./about.route";
import AnnualProductRoutes from "./annualProducts.route";
import CarriersRoutes from "./carriers.route";
import ClaimsRoutes from "./claims.route";
import ContactsRoutes from "./contacts.route";
import DashboardRoutes from "./dashboard.route";
import IntegrationRoutes from "./integration.route";
import UnderwriterRoutes from "./underwriter.route";

const RoutesMap: iRoute[] = [
  ...DashboardRoutes,
  ...CarriersRoutes,
  ...ContactsRoutes,
  ...QuoteOrBuyRoutes,
  ...ViewCertificateRoutes,
  ...ViewQuoteRoutes,
  ...AnnualProductRoutes,
  ...ClaimsRoutes,
  ...PartnerSettingsRoutes,
  ...EditPortalSettingsRoutes,
  ...EditMasterPoliciesRoutes,
  ...UsersRoutes,
  ...PartnersRoutes,
  ...SanctionsChecksRoutes,
  ...TaxesRoutes,
  ...LicensingRoutes,
  ...PartnerInvoiceRoutes,
  ...IntegrationRoutes,
  ...UnderwriterRoutes,
  ...AboutRoutes
];

export default RoutesMap;


  import {
    Ref,
    computed,
    defineComponent,
    onMounted,
    ref,
    toRef,
    watch
  } from "@nuxtjs/composition-api";

  export default defineComponent({
    name: "InputGeneric",
    props: {
      label: {
        type: String,
        required: true
      },
      id: {
        type: String,
        default: undefined
      },
      errors: { type: String, default: "" },
      value: {
        type: [String, Number],
        default: ""
      },
      type: {
        type: String,
        required: true
      },
      disabled: Boolean,
      isOptional: Boolean,
      hideOptionalLabel: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ["update:value", "blurred", "change", "isInvalid", "input", "focus"],
    setup(props, { emit }) {
      const labelRef: Ref<any> = ref("");
      const labelWidth = ref(0);
      const errorsRef = toRef(props, "errors");
      const inputFocused = ref(false);
      const fieldSet = ref(null) as Ref<any | null>;
      const isInputActive = computed(() => {
        const { value } = props;
        if (typeof value === "number" && value === 0) {
          return true;
        }
        return !!value;
      });

      const setLegendWidth = () => {
        if (labelRef.value) {
          labelWidth.value = labelRef.value.clientWidth * 0.8 + 8;
        }
      };

      const handleBlur = () => {
        inputFocused.value = false;
        emit("blurred");
      };

      const handleFocus = () => {
        inputFocused.value = true;
        setLegendWidth();
        emit("focus");
      };

      const resetNumberToNull = () => {
        const { type, value } = props;
        if (value === "" && type === "number") {
          emit("input", null);
        }
      };

      const handleChange = () => {
        emit("change");
      };

      const selfFocus = () => {
        fieldSet.value.focus();
      };

      watch(errorsRef, (newValue) => {
        if (newValue) {
          emit("isInvalid");
        }
      });

      onMounted(() => {
        resetNumberToNull();
        setLegendWidth();
      });

      watch(
        () => props.value,
        () => {
          resetNumberToNull();
          setLegendWidth();
        }
      );

      watch(labelRef, setLegendWidth);

      return {
        labelRef,
        labelWidth,
        inputFocused,
        isInputActive,
        handleBlur,
        fieldSet,
        selfFocus,
        handleChange,
        setLegendWidth,
        handleFocus
      };
    }
  });

import { iRoute } from "../types/types";

const AboutRoutes: iRoute[] = [
  {
    paths: ["/about/loadsure", "/about/internetTradingPolicy"],
    permissions: [],
    allowAccessAll: true
  }
];

export default AboutRoutes;
